import { getServerHelpers } from "../../helpers";

export async function money__server__startStripeAccountSession(p: {}) {
  // SERVER_ONLY_TOGGLE
  const { injectedServerLibraries } = getServerHelpers();
  // const stripe = injectedServerLibraries.stripe;

  // const testAccountId = "acct_1McDzIGbwJPXIVNd";
  // try {
  //   const accountSession = await stripe.accountSessions.create({
  //     account: testAccountId
  //   });

  //   return accountSession.client_secret;
  // } catch (error) {
  //   console.log(error);
  //   console.error("An error occurred when calling the Stripe API to create an account session", error);
  // }

  // SERVER_ONLY_TOGGLE
}

money__server__startStripeAccountSession.auth = () => {};
// i18n certified - complete
