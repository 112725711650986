import { OrgId } from "@ollie-sports/models";
import { getServerHelpers } from "../../helpers";

export async function money__server__getStripeAccount(p: { stripeAccountId: string }) {
  // SERVER_ONLY_TOGGLE
  const { injectedServerLibraries, appOllieFirestoreV2: h } = getServerHelpers();
  const stripe = injectedServerLibraries.stripe;

  try {
    const account = await stripe.accounts.retrieve(p.stripeAccountId);

    return account;
  } catch (e) {
    return undefined;
  }

  // SERVER_ONLY_TOGGLE
}

money__server__getStripeAccount.auth = () => {};
// i18n certified - complete
