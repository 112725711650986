import { OrgId } from "@ollie-sports/models";
import { getServerHelpers } from "../../helpers";

export async function money__server__addStripeAccountForOrg(p: {
  orgId: OrgId;
  returnUrl: string;
  refreshUrl: string;
}): Promise<{ status: "good"; url: string } | { status: "error-org-already-has-stripe-account" }> {
  // SERVER_ONLY_TOGGLE
  const { injectedServerLibraries, appOllieFirestoreV2: h, serverConfig } = getServerHelpers();
  const stripe = injectedServerLibraries.stripe;

  const [org, newAccount] = await Promise.all([
    h.Org.getDoc(p.orgId),
    stripe.accounts.create({
      // @ts-ignore
      type: "standard"
    })
  ]);

  if (!org) {
    throw new Error("Org does not exist");
  }

  if (org.stripeAccountId) {
    return { status: "error-org-already-has-stripe-account" };
  }

  const [accountLink] = await Promise.all([
    // @ts-ignore
    stripe.accountLinks.create({
      account: newAccount.id,
      refresh_url: p.refreshUrl,
      return_url: p.returnUrl,
      type: "account_onboarding"
    }),
    h.Org.update({
      id: p.orgId,
      doc: {
        stripeAccountId: newAccount.id
      }
    })
  ]);

  return { status: "good", url: accountLink.url };

  // SERVER_ONLY_TOGGLE
}

money__server__addStripeAccountForOrg.auth = () => {};
// i18n certified - complete
