import { OrgId } from "@ollie-sports/models";
import { getServerHelpers } from "../../helpers";

export async function money__server__getStripeAccountUpdateLink(p: { accountId: string; returnUrl: string; refreshUrl: string }) {
  // SERVER_ONLY_TOGGLE
  const { injectedServerLibraries, appOllieFirestoreV2: h, serverConfig } = getServerHelpers();
  const stripe = injectedServerLibraries.stripe;

  try {
    const accountLink = await stripe.accountLinks.create({
      account: p.accountId,
      refresh_url: p.refreshUrl,
      return_url: p.returnUrl,
      type: "account_onboarding"
    });
    return accountLink.url;
  } catch (e) {
    console.log("-------ERROR-------");
    console.log(e);
    return null;
  }

  // SERVER_ONLY_TOGGLE
}

money__server__getStripeAccountUpdateLink.auth = () => {};
// i18n certified - complete
